import { createRouter, createWebHistory, createMemoryHistory } from "vue-router";

import RouteInformations from "@/models/RouteInformations";
import { AccessErrorCodes, TransactionTypes } from "@/constants";
import PaymentSolutionsMissingInformation from "@/components/screens/PaymentSolutions/PaymentSolutionsMissingInformation.vue";
import FunnelInformationSplit from "@/components/screens/FunnelInformationSplit.vue";
import Funnel2FA from "@/components/screens/Funnel2FA.vue";
import FunnelIdentification from "@/components/screens/FunnelIdentification.vue";
import FunnelEntrypoint from "@/components/screens/FunnelEntrypoint.vue";
import FunnelIntroduction from "@/components/screens/FunnelIntroduction.vue";
import FunnelInformationMissingPreCreation from "@/components/screens/FunnelInformationMissingPreCreation.vue";
import FunnelRevenue from "@/components/screens/FunnelRevenue.vue";
import FunnelSummary from "@/components/screens/FunnelSummary.vue";
import FunnelInformationMissingPostCreation from "@/components/screens/FunnelInformationMissingPostCreation.vue";
import FunnelCheckout from "@/components/screens/FunnelCheckout.vue";
import FunnelSuccess from "@/components/screens/FunnelSuccess.vue";

import FunnelProcessing from "@/components/screens/FunnelProcessing.vue";
import FunnelProcessingPostSignature from "@/components/screens/FunnelProcessingPostSignature.vue";
import FunnelAncv from "@/components/screens/FunnelAncv.vue";
import DashboardBuyerOrderOverview from "@/components/screens/DashboardBuyer/OrderOverview.vue";
import DashboardBuyerPurchaseOverview from "@/components/screens/DashboardBuyer/PurchaseOverview.vue";
import PaymentSolutionsList from "@/components/screens/PaymentSolutions/PaymentSolutionsList.vue";
import PaymentSolutionsPreCheck from "@/components/screens/PaymentSolutions/PaymentSolutionsPreCheck.vue";
import AccessErrorScreen from "@/components/screens/AccessErrorScreen.vue";

import { ROUTE_PARAMS, ROUTE_QUERY_PARAMS, ROUTE_NAMES } from "./constants/routes";

const { PAYMENT_FUNNEL_UID, PURCHASE_UID, SHARE_UID, ORDER_UID, ACCESS_ERROR_TYPE } =
  ROUTE_PARAMS;
const { COMPANY_UID } = ROUTE_QUERY_PARAMS;

const routes = [
  {
    path: "",
    redirect: (to) => {
      const routeInformations = new RouteInformations(to);
      if (COMPANY_UID in routeInformations.data) {
        return { name: ROUTE_NAMES.PAYMENT_SOLUTIONS };
      }
      if (routeInformations.transaction_type === TransactionTypes.ORDER) {
        return { name: ROUTE_NAMES.FUNNEL_ORDER_ENTRYPOINT };
      }
      return { name: ROUTE_NAMES.FUNNEL_ENTRYPOINT };
    },
    meta: { layout: "funnel" },
  },
  {
    path: "/purchase",
    name: ROUTE_NAMES.FUNNEL_ENTRYPOINT,
    component: FunnelEntrypoint,
    meta: { layout: "funnel" },
  },
  {
    path: "/order",
    name: ROUTE_NAMES.FUNNEL_ORDER_ENTRYPOINT,
    component: FunnelEntrypoint,
    meta: { layout: "funnel" },
  },
  {
    path: `/purchase/:${PURCHASE_UID}/ancv_share`,
    name: ROUTE_NAMES.FUNNEL_ANCV,
    component: FunnelAncv,
    meta: { layout: "funnel" },
  },
  {
    path: "/introduction",
    name: ROUTE_NAMES.FUNNEL_INTRODUCTION,
    component: FunnelIntroduction,
    meta: { layout: "funnel" },
  },
  {
    path: "/purchase-missing-information",
    name: ROUTE_NAMES.FUNNEL_INFORMATION_MISSING_PRE_CREATION,
    component: FunnelInformationMissingPreCreation,
    meta: { layout: "funnel" },
  },
  {
    path: `/purchase/:${PURCHASE_UID}/shares`,
    name: ROUTE_NAMES.FUNNEL_INFORMATION_SPLIT,
    component: FunnelInformationSplit,
    meta: { layout: "funnel" },
  },
  {
    path: `/purchase/:${PURCHASE_UID}/:${SHARE_UID}/introduction`,
    name: ROUTE_NAMES.FUNNEL_INTRODUCTION_POST_CREATION,
    component: FunnelIntroduction,
    meta: { layout: "funnel" },
  },
  {
    path: `/purchase/:${PURCHASE_UID}/:${SHARE_UID}/2FA`,
    name: ROUTE_NAMES.FUNNEL_2FA,
    component: Funnel2FA,
    meta: { layout: "funnel" },
  },
  {
    path: `/purchase/:${PURCHASE_UID}/:${SHARE_UID}/information`,
    name: ROUTE_NAMES.FUNNEL_INFORMATION_MISSING_POST_CREATION,
    component: FunnelInformationMissingPostCreation,
    meta: { layout: "funnel" },
  },
  {
    path: `/order/:${ORDER_UID}/2FA`,
    name: ROUTE_NAMES.FUNNEL_ORDER_2FA,
    component: Funnel2FA,
    meta: { layout: "funnel" },
  },
  {
    path: `/purchase/:${PURCHASE_UID}/:${SHARE_UID}/identification`,
    name: ROUTE_NAMES.FUNNEL_IDENTIFICATION,
    component: FunnelIdentification,
    meta: { layout: "funnel" },
  },
  {
    path: `/purchase/:${PURCHASE_UID}/:${SHARE_UID}/revenue`,
    name: ROUTE_NAMES.FUNNEL_REVENUE,
    component: FunnelRevenue,
    meta: { layout: "funnel" },
  },
  {
    path: `/purchase/:${PURCHASE_UID}/:${SHARE_UID}/checkout`,
    name: ROUTE_NAMES.FUNNEL_CHECKOUT,
    component: FunnelCheckout,
    meta: { layout: "funnel" },
  },
  {
    path: `/purchase/:${PURCHASE_UID}/:${SHARE_UID}/payment_method/update`,
    name: ROUTE_NAMES.FUNNEL_PAYMENT_METHOD_UPDATE,
    component: FunnelCheckout,
    meta: { layout: "funnel" },
  },
  {
    path: `/purchase/:${PURCHASE_UID}/:${SHARE_UID}/summary`,
    name: ROUTE_NAMES.FUNNEL_SUMMARY,
    component: FunnelSummary,
    meta: { layout: "funnel" },
  },
  {
    path: `/order/:${ORDER_UID}/checkout`,
    name: ROUTE_NAMES.FUNNEL_ORDER_CHECKOUT,
    component: FunnelCheckout,
    meta: { layout: "funnel" },
  },
  {
    /*
        Checkout funnel refactor changed steps, keep routes
        for compatibility, redirect to generic checkout step
      */
    path: `/purchase/:${PURCHASE_UID}/:${SHARE_UID}/checkout_apm`,
    redirect: (to) => {
      const { params } = to;
      return {
        name: ROUTE_NAMES.FUNNEL_CHECKOUT,
        params,
      };
    },
    meta: { layout: "funnel" },
  },
  {
    path: `/confirmation/:${PURCHASE_UID}/:${SHARE_UID}`,
    name: ROUTE_NAMES.FUNNEL_PROCESSING,
    components: {
      default: FunnelProcessing,
      checkout: FunnelProcessing,
    },
    meta: { layout: "funnel" },
  },
  {
    path: `/confirmation/:${PURCHASE_UID}/:${SHARE_UID}/completion`,
    name: ROUTE_NAMES.FUNNEL_PROCESSING_POST_SIGNATURE,
    components: {
      default: FunnelProcessingPostSignature,
      checkout: FunnelProcessing,
    },
    meta: { layout: "funnel" },
  },
  {
    path: `/confirmation/:${ORDER_UID}`,
    name: ROUTE_NAMES.FUNNEL_ORDER_PROCESSING,
    component: FunnelProcessing,
    meta: { layout: "funnel" },
  },
  {
    path: `/success/:${PURCHASE_UID}/:${SHARE_UID}`,
    name: ROUTE_NAMES.FUNNEL_SUCCESS,
    component: FunnelSuccess,
    meta: { layout: "funnel" },
  },
  {
    path: `/dashboard/purchases/:${PURCHASE_UID}`,
    name: ROUTE_NAMES.DASHBOARD_BUYER_PURCHASE_OVERVIEW,
    component: DashboardBuyerPurchaseOverview,
    meta: { layout: "dashboard" },
  },
  {
    path: `/dashboard/orders/:${ORDER_UID}`,
    name: ROUTE_NAMES.DASHBOARD_BUYER_ORDER_OVERVIEW,
    component: DashboardBuyerOrderOverview,
    meta: { layout: "dashboard" },
  },
  {
    path: "/payment_solutions",
    name: ROUTE_NAMES.PAYMENT_SOLUTIONS,
    meta: { layout: "payment-solutions" },
    redirect: { name: ROUTE_NAMES.PAYMENT_SOLUTIONS_PRECHECK },
  },
  {
    path: "/payment_solutions/aggregate",
    name: ROUTE_NAMES.PAYMENT_SOLUTIONS_PRECHECK,
    component: PaymentSolutionsPreCheck,
    meta: { layout: "payment-solutions" },
  },
  {
    path: `/payment_solutions/aggregate/:${PAYMENT_FUNNEL_UID}/missing_information`,
    name: ROUTE_NAMES.PAYMENT_SOLUTIONS_MISSING_INFORMATION,
    component: PaymentSolutionsMissingInformation,
    meta: { layout: "payment-solutions" },
  },
  {
    path: `/payment_solutions/aggregate/:${PAYMENT_FUNNEL_UID}`,
    name: ROUTE_NAMES.PAYMENT_SOLUTIONS_LIST,
    component: PaymentSolutionsList,
    meta: { layout: "payment-solutions" },
  },
  {
    path: `/error/:${ACCESS_ERROR_TYPE}`,
    name: ROUTE_NAMES.ACCESS_ERROR,
    component: AccessErrorScreen,
    meta: { layout: "blank" },
  },
  {
    // Catch all and redirect to 404 error
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    redirect: () => {
      return {
        name: ROUTE_NAMES.ACCESS_ERROR,
        params: {
          [ACCESS_ERROR_TYPE]: AccessErrorCodes.ERROR_404,
        },
      };
    },
  },
];

// Unfortunately use createMemoryHistory for testing purpose
const history =
  typeof window === "undefined" ? createMemoryHistory() : createWebHistory();

const router = createRouter({
  history,
  routes,
  scrollBehavior() {
    return { left: 0, top: 0 };
  },
});

export default router;
