import Sentry from "@/plugins/sentry";
import errorParser from "@/helpers/errorParser";
import httpService from "./httpService";

const ENDPOINTS = {
  cancelSignature: (purchaseUid) => `purchases/${purchaseUid}/cancel_signature`,
  purchase: (purchaseUid) => `purchases/${purchaseUid}`,
  revenue: (purchaseUid) => `purchases/${purchaseUid}/revenue`,
  summary_validation: (purchaseUid) => `purchases/${purchaseUid}/summary`,
  advertisement_optin: (purchaseUid) => `purchases/${purchaseUid}/advertisement_optin`,
  marketing_decision: (purchaseUid) => `purchases/${purchaseUid}/marketing_decision`,
  dashboardPurchase: (purchaseUid) => `dashboard/purchases/${purchaseUid}`,
  createShares: (purchaseUid) => `purchases/${purchaseUid}/shares`,
  createShareANCV: (purchaseUid) => `purchases/${purchaseUid}/share_ancv`,
  paymentMethodUpdate: (purchaseUid) =>
    `payment_method_update/purchases/${purchaseUid}`,
  missingInfos: (purchaseUid) => `purchases/${purchaseUid}/missing_infos`,
  contract: (purchaseUid) => `purchases/${purchaseUid}/contract`,
};

async function cancelSignature(purchaseUid, data) {
  const url = ENDPOINTS.cancelSignature(purchaseUid);
  return httpService.post(url, null, data);
}

async function setStarted(purchaseUid) {
  const url = ENDPOINTS.purchase(purchaseUid);
  try {
    await httpService.put(url);
  } catch (error) {
    Sentry.error("purchase-set-started", {
      url,
      ...errorParser(error),
    });
  }
}

async function updateMissingInfos(purchaseUid, formData) {
  const {
    birth_city,
    birth_country,
    birth_date,
    birth_zipcode,
    birth_last_name,
    civility,
    nationality,
  } = formData;
  const url = ENDPOINTS.missingInfos(purchaseUid);
  return httpService.patch(url, null, {
    birth_city,
    birth_country,
    birth_date,
    birth_zipcode,
    birth_last_name,
    civility,
    nationality,
  });
}

async function setRevenue(
  purchaseUid,
  declaredRevenue,
  declaredExpenditure,
  declaredCSP
) {
  const url = ENDPOINTS.revenue(purchaseUid);
  return httpService.patch(url, null, {
    declared_revenue: declaredRevenue,
    declared_expenditure: declaredExpenditure,
    declared_csp: declaredCSP,
  });
}

async function summaryValidation(purchaseUid) {
  const url = ENDPOINTS.summary_validation(purchaseUid);
  return httpService.patch(url, null, {});
}

async function setAdvertisementOptin(purchaseUid, advertisement_optin) {
  const url = ENDPOINTS.advertisement_optin(purchaseUid);
  try {
    return httpService.patch(url, null, { advertisement_optin });
  } catch (error) {
    Sentry.error("set-advertisement-optin-error", {
      url,
      ...errorParser(error),
    });
  }

  return undefined;
}

async function updateMarketingDecision(
  purchaseUid,
  electronic_optin,
  phone_mail_optout
) {
  const url = ENDPOINTS.marketing_decision(purchaseUid);
  try {
    return httpService.patch(url, null, { electronic_optin, phone_mail_optout });
  } catch (error) {
    Sentry.error("update-marketing-decision-error", {
      url,
      ...errorParser(error),
    });
  }

  return undefined;
}

async function fetchContract(purchaseUid, params, options) {
  const url = ENDPOINTS.contract(purchaseUid);
  return httpService.get(url, params, {}, options);
}

async function signContract(purchaseUid) {
  const url = ENDPOINTS.contract(purchaseUid);
  return httpService.patch(url, null, {});
}

async function fetchPurchase(purchaseUid, { isDashboardBuyer, isPaymentMethodUpdate }) {
  if (isDashboardBuyer) {
    return httpService.get(ENDPOINTS.dashboardPurchase(purchaseUid));
  }
  if (isPaymentMethodUpdate) {
    return httpService.get(ENDPOINTS.paymentMethodUpdate(purchaseUid));
  }
  return httpService.get(ENDPOINTS.purchase(purchaseUid));
}

async function createShares(purchaseUid, params = {}, bodyData = {}, isAncv = false) {
  const url = isAncv
    ? ENDPOINTS.createShareANCV(purchaseUid)
    : ENDPOINTS.createShares(purchaseUid);

  return httpService.post(url, params, bodyData);
}

const PurchaseService = {
  cancelSignature,
  setStarted,
  fetchPurchase,
  fetchContract,
  createShares,
  setRevenue,
  summaryValidation,
  setAdvertisementOptin,
  updateMarketingDecision,
  updateMissingInfos,
  signContract,
};

export default PurchaseService;
